<template>
  <section>
    <div class="container">
      <h2 data-aos="fade-up">Nos tarifs</h2>
      <div class="grid">
        <div class="col-1">
          <h3 data-aos="fade-up">Mesdames</h3>
          <PricesList :prices="prices?.women ?? []" />
        </div>
        <div class="col-2">
          <h3 data-aos="fade-up">Traitement OLAPLEX</h3>
          <div class="subtitle">(Renforce et répare les cheveux)</div>
          <PricesList :prices="prices?.olaplex ?? []" />
          <h3 data-aos="fade-up">Messieurs</h3>
          <PricesList :prices="prices?.men ?? []" />
          <h3 data-aos="fade-up">Mini</h3>
          <PricesList :prices="prices?.mini ?? []" />
          <h3 data-aos="fade-up">Etudiants</h3>
          <h4 data-aos="fade-up">Homme</h4>
          <PricesList :prices="prices?.students?.men ?? []" />
          <h4 data-aos="fade-up">Femme</h4>
          <PricesList :prices="prices?.students?.women ?? []" />
          <div class="italic">5% de remise sur les autres services (sauf lissage brésilien)</div>
          <div class="italic">Non cumulable avec d'autres promotions.</div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import PricesList from './PricesList.vue';

export default {
  name: 'Prices',
  components: {
    PricesList,
  },
  data() {
    return {
      prices: null,
    };
  },
  created() {
    this.getPrices();
  },
  methods: {
    async getPrices() {
      const prices = await (await window.fetch('/prices.json')).json();
      this.prices = prices;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../styles/colors.scss';
@import '../styles/responsive.scss';
section {
  background-color: $primary;
  color: $ternary;
  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    @media screen and (max-width: $responsive-l) {
      grid-template-columns: repeat(1, 1fr);
    }
    .col-1 {
      padding: 20px;
      @media screen and (max-width: $responsive-l) {
        padding: 20px 20px 0 20px;
      }
      grid-column: 1;
    }
    .col-2 {
      padding: 20px;
      grid-column: 2;
      @media screen and (max-width: $responsive-l) {
        grid-column: 1;
        padding: 0 20px 20px 20px;
      }
    }
    h3 {
      color: $secondary;
      font-family: 'Pacifico', serif;
      letter-spacing: 2px;
    }
    .subtitle {
      color: $silver;
      font-weight: bold;
      position: relative;
      bottom: 20px;
    }
  }
  h2 {
    border-bottom: 2px solid $ternary;
  }
  h4 {
    margin: 10px 0;
  }
  .italic {
    font-style: italic;
  }
}
</style>
