<template>
  <section>
    <div class="anchor" id="realisations"></div>
    <div class="dark">
      <div class="container" id="realisations-section">
        <h2 data-aos="fade-up">Nos réalisations</h2>
        <div class="realisations">
          <img :src="require('@/assets/rea1.jpg')" data-aos="fade-up" class="rea-img" />
          <img :src="require('@/assets/rea2.jpg')" data-aos="fade-up" class="rea-img" />
          <img :src="require('@/assets/rea3.jpg')" data-aos="fade-up" class="rea-img" />
          <img :src="require('@/assets/rea4.jpg')" data-aos="fade-up" class="rea-img" />
          <img :src="require('@/assets/rea5.jpg')" data-aos="fade-up" class="rea-img" />
          <img :src="require('@/assets/rea6.jpg')" data-aos="fade-up" class="rea-img" />
          <img :src="require('@/assets/rea7.jpg')" data-aos="fade-up" class="rea-img" />
          <img :src="require('@/assets/rea8.jpg')" data-aos="fade-up" class="rea-img" />
          <img :src="require('@/assets/rea9.jpg')" data-aos="fade-up" class="rea-img" />
          <img :src="require('@/assets/rea10.jpg')" data-aos="fade-up" class="rea-img" />
          <img :src="require('@/assets/rea11.jpg')" data-aos="fade-up" class="rea-img" />
          <img :src="require('@/assets/rea12.jpg')" data-aos="fade-up" class="rea-img" />
        </div>
      </div>
    </div>
    <!--<div class="anchor" id="gamme"></div>
    <div class="" id="gamme-section">
      <div class="container">
        <h2 data-aos="fade-up">La gamme</h2>
        <div style="height: 400px">TODO</div>
      </div>
    </div>-->
    <div class="anchor" id="saloon"></div>
    <div class="dark">
      <div class="container" id="saloon-section">
        <h2 data-aos="fade-up">Le salon</h2>
        <div class="saloon">
          <div class="flex">
            <div class="place-img-container" data-aos="fade-up">
              <img class="place-img" :src="require('@/assets/place1.jpg')" />
            </div>
            <div class="place-img-container" data-aos="fade-up">
              <img class="place-img" :src="require('@/assets/place4.jpg')" />
            </div>
          </div>
          <div class="place-img-container" data-aos="fade-up">
            <img class="place-img" :src="require('@/assets/place3.jpg')" />
          </div>
          <div class="place-img-container" data-aos="fade-up">
            <img class="place-img" :src="require('@/assets/place5.jpg')" />
          </div>
          <div class="place-img-container" data-aos="fade-up">
            <img class="place-img" :src="require('@/assets/place2.jpg')" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
// import Carrousel from './Carrousel.vue';

export default {
  name: 'Place',
  // components: { Carrousel },
};
</script>
<style lang="scss" scoped>
@import '../styles/colors.scss';
@import '../styles/responsive.scss';
section {
  background-color: $primary;
  color: $ternary;
}
h2 {
  border-bottom: 2px solid $primary;
}
.realisations {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  .rea-img {
    max-height: 60vh;
    max-width: 40%;
    margin: 10px;
    @media screen and (max-width: $responsive-m) {
      max-width: 100%;
    }
  }
}
.saloon {
  text-align: center;
  .flex {
    display: flex;
    justify-content: center;
  }
  .place-img-container {
    margin: 7px;
  }
  .place-img {
    max-height: 65vh;
    max-width: 100%;
    margin: auto;
    border-radius: 5px;
  }
}
.dark {
  background-color: $primary;
  h2 {
    color: $ternary;
    border-bottom: 2px solid $ternary;
  }
}
</style>
