<template>
  <section>
    <div class="container">
      <h2 data-aos="fade-up">Notre équipe</h2>
      <div class="profiles">
        <div class="manager">
          <Profile
            :image="require('@/' + profiles[0].image)"
            :name="profiles[0].name"
            :title="profiles[0].title"
            :text="profiles[0].text"
            :isOneLine="true"
          />
        </div>
        <div class="first-employee">
          <Profile
            :image="require('@/' + profiles[1].image)"
            :name="profiles[1].name"
            :title="profiles[1].title"
            :text="profiles[1].text"
          />
        </div>
        <div class="second-employee">
          <Profile
            :image="require('@/' + profiles[2].image)"
            :name="profiles[2].name"
            :title="profiles[2].title"
            :text="profiles[2].text"
          />
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Profile from './Profile.vue';

export default {
  name: 'Team',
  components: { Profile },
  data() {
    return {
      profiles: [
        {
          image: 'assets/natacha.jpeg',
          name: 'Natacha',
          title: 'Gérante - Coiffeuse',
          text: `Passionnée par la coiffure dès mon plus jeune âge, j'ai été formée à l'institut Maria Goretti d'Angleur.
En 2014, je reprends une formation en chef d'entreprise afin d'ouvrir mon salon.
Natiff Coiffure voit donc le jour en 2015 et aura de nouveaux locaux en 2019.
Mes maîtres mots : Passion - rigueur - formation - satisfaction.`,
        },
        {
          image: 'assets/joyce.jpeg',
          name: 'Joyce',
          title: 'Coiffeuse',
          text: `Étant passionnée par la mode et les nouvelles tendances, ce métier artistique me convient vraiment.
Les nouvelles  tendances impliquent une formation continue et j'aime particulièrement les techniques de colorimétrie et je me spécialise donc en coloration.
J'attache beaucoup d'importance à la satisfaction de la clientèle et participe donc au bien être des personnes.`,
        },
        {
          image: 'assets/elena.jpeg',
          name: 'Elena',
          title: 'Étudiante',
          text: `Passionnée par la coiffure , j'entreprends mes études à l'EP de Huy. 
J'ai l'opportunité de pouvoir me former dans le salon de ma maman.`,
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
@import '../styles/colors.scss';
@import '../styles/responsive.scss';
.profiles {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-template-rows: repeat(3, auto);
  grid-gap: 7px;
  .manager {
    grid-row: 1;
    grid-column-start: 1;
    grid-column-end: 5;
    @media screen and (max-width: $responsive-m) {
      grid-column-start: 1;
      grid-column-end: 4;
    }
  }
  .first-employee {
    grid-row: 2;
    grid-column-start: 1;
    grid-column-end: 3;
    @media screen and (max-width: $responsive-m) {
      grid-column-end: 4;
    }
  }
  .second-employee {
    grid-row: 2;
    grid-column-start: 3;
    grid-column-end: 5;
    @media screen and (max-width: $responsive-m) {
      grid-row: 3;
      grid-column-start: 1;
    }
  }
}
section {
  background-color: $primary;
  color: $ternary;
}
h2 {
  border-bottom: 2px solid $primary;
}
</style>
