<template>
  <div class="header" :class="{ opacity1: isOpacity1 }" id="header">
    <div class="logo"></div>
    <div class="nav" id="nav">
      <a
        v-for="link in links"
        :key="link.id"
        v-bind:class="[link.id, { active: active == link.id }]"
        v-bind:href="'#' + link.id"
        >{{ link.text }}</a
      >
      <div class="social-logo">
        <a
          href="https://www.facebook.com/natiffcoiffure/"
          target="_blank"
          class="gg-facebook w-40px"
        ></a>
        <a
          href="https://www.instagram.com/natiffcoiffure/"
          target="_blank"
          class="gg-instagram w-40px"
        ></a>
        <a href="tel:0471724591" class="gg-phone w-40px"></a>
      </div>
      <div class="burger-menu">
        <a href="" class="gg-menu" v-on:click="toggleBurger"></a>
      </div>
    </div>
    <div class="side" v-bind:class="{ open: isBurgerOpened }">
      <a href="" class="gg-close" v-on:click="toggleBurger"></a>
      <ul>
        <li
          v-for="link in links"
          :key="link.id"
          v-bind:class="[link.id, { active: active == link.id }]"
        >
          <a v-bind:href="'#' + link.id" v-on:click="closeBurger">{{ link.text }}</a>
        </li>
      </ul>
    </div>
    <div class="overlay" v-bind:class="{ open: isBurgerOpened }" v-on:click="toggleBurger"></div>
  </div>
</template>

<script>
export default {
  name: 'Header',
  data() {
    return {
      active: null,
      nav: null,
      isMinified: false,
      isBurgerOpened: false,
      isOpacity1: false,
      aboutTitleEl: null,
      links: [
        {
          id: 'about',
          text: 'À propos',
        },
        {
          id: 'team',
          text: 'Équipe',
        },
        {
          id: 'realisations',
          text: 'Réalisations',
        },
        /* {
          id: 'gamme',
          text: 'Gamme',
        }, */
        {
          id: 'saloon',
          text: 'Salon',
        },
        {
          id: 'prices',
          text: 'Tarifs',
        },
        {
          id: 'contact',
          text: 'Contact',
        },
      ],
    };
  },
  methods: {
    checkActive() {
      this.links
        .map((l) => l.id)
        .forEach((id) => {
          if (this.isActive(document.getElementById(`${id}-section`))) {
            this.active = id;
          }
        });
    },
    checkOpacity() {
      this.isOpacity1 = this.aboutTitleEl.getBoundingClientRect().top <= 100;
    },
    /**
     * @param {HTMLElement} el
     */
    isActive(el) {
      return el.getBoundingClientRect().top < window.innerHeight / 2;
    },
    /**
     * @param {MouseEvent} event
     */
    toggleBurger(event) {
      event.preventDefault();
      this.isBurgerOpened = !this.isBurgerOpened;
      return false;
    },
    closeBurger() {
      this.isBurgerOpened = false;
    },
  },
  mounted() {
    this.aboutTitleEl = document.getElementById('aboutTitle');
    this.nav = document.getElementById('nav');
    this.header = document.getElementById('header');
    window.addEventListener('scroll', () => {
      this.checkActive();
      this.checkOpacity();
    });
    this.checkActive();
    this.checkOpacity();
  },
};
</script>

<style lang="scss" scoped>
@import '../styles/colors.scss';
@import '../styles/responsive.scss';
$transition-time: 0.5s;
@mixin center {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.nav-item {
  @include center;
  font-size: 20px;
  @media screen and (max-width: $responsive-m) {
    display: none;
  }
  @media screen and (max-width: $responsive-l) {
    font-size: 15px;
  }
}
.header {
  -webkit-transition: all $transition-time ease;
  -moz-transition: all $transition-time ease;
  -o-transition: all $transition-time ease;
  transition: all $transition-time ease;
  display: grid;
  grid-template-columns: 100px 1fr;
  //   background-color: $primary-light;
  height: 80px;
  padding: 10px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  &.opacity1 {
    background-color: $primary;
    @media screen and (max-width: $responsive-s) {
      border-bottom: 2px solid $ternary;
    }
  }
  .logo {
    grid-column: 1;
    background: url('../assets/logo.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 100%;
    width: 100%;
  }
  .nav {
    .active {
      border-bottom: 2px solid $ternary;
    }
    grid-column: 2;
    display: flex;
    color: $ternary;
    .about {
      @extend .nav-item;
      grid-column: 1;
    }
    .team {
      @extend .nav-item;
    }
    .realisations {
      @extend .nav-item;
    }
    .gamme {
      @extend .nav-item;
    }
    .saloon {
      @extend .nav-item;
    }
    .prices {
      @extend .nav-item;
    }
    .contact {
      @extend .nav-item;
    }
    a {
      color: $ternary;
      text-decoration: none;
      &:hover {
        color: $secondary;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        transition: all 0.5s ease;
      }
    }
    .burger-menu {
      @include center;
      // Pour tablette et smartphone
      // Pour PC
      @media screen and (min-width: $responsive-m) {
        display: none;
      }
      .gg-menu {
        color: $ternary;
        display: block;
        &:hover {
          color: $secondary;
          -webkit-transition: all 0.5s ease;
          -moz-transition: all 0.5s ease;
          -o-transition: all 0.5s ease;
          transition: all 0.5s ease;
        }
      }
    }
    .social-logo {
      @include center;
      grid-row: 1;
      .gg-facebook {
        color: $ternary;
        &:hover {
          color: $secondary;
          -webkit-transition: all 0.5s ease;
          -moz-transition: all 0.5s ease;
          -o-transition: all 0.5s ease;
          transition: all 0.5s ease;
        }
      }
      @media screen and (max-width: $responsive-m) {
        grid-column-start: 1;
        grid-column-end: 7;
        // display: none;
      }
      @media screen and (min-width: $responsive-m) {
        grid-column-start: 8;
        grid-column-end: 9;
      }
    }
    .w-40px {
      margin: 0 15px;
    }
  }
}
.side {
  position: fixed;
  top: 0;
  right: 0;
  width: 0vw;
  height: 100vh;
  background: $secondary;
  z-index: 1000;
  &.open {
    width: 50vw;
  }
  -webkit-transition: all $transition-time ease;
  -moz-transition: all $transition-time ease;
  -o-transition: all $transition-time ease;
  transition: all $transition-time ease;
  .gg-close {
    color: $ternary;
    margin: 20px;
    --ggs: 2;
    &:hover {
      color: $primary;
      -webkit-transition: all 0.5s ease;
      -moz-transition: all 0.5s ease;
      -o-transition: all 0.5s ease;
      transition: all 0.5s ease;
    }
  }
  li {
    list-style: none;
    font-size: 20px;
    padding: 10px;
    a {
      color: $ternary;
      text-decoration: none;
      &:hover {
        color: $primary;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        transition: all 0.5s ease;
      }
    }
    &.active {
      border-left: 3px solid $primary;
    }
  }
}
.overlay {
  position: fixed; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  display: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
  &.open {
    display: inline;
  }
}
</style>
