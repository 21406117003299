<template>
  <ul data-aos="fade-up">
    <li class="list" v-for="(priceSection, index) in prices" :key="index">
      <ul>
        <li v-for="price in priceSection" :key="price.name">
          <div class="item">
            <div class="label">{{ price.name }}</div>
            <div v-if="price.price" class="price">
              <span>{{ price.price }}</span>
            </div>
          </div>
          <ul v-if="price.subs">
            <li v-for="sub in price.subs" :key="sub.name" class="li_sub">
              <div class="subitem">
                <div class="label">&#x21aa; {{ sub.name }}</div>
                <div class="price">{{ sub.price }}</div>
              </div>
            </li>
          </ul>
        </li>
      </ul>
    </li>
  </ul>
</template>
<script>
export default {
  name: 'PricesList',
  props: ['prices'],
};
</script>
<style lang="scss" scoped>
.list {
    margin-bottom: 15px;
}
li {
  padding: 5px 0;
  list-style: none;
}
ul {
  padding: 0px 0;
}
.item {
  display: grid;
  grid-template-columns: 1fr auto;
}
.label {
  grid-column: 1;
  padding-right: 10px;
}
.price {
  grid-column: 2;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.subitem {
  display: grid;
  grid-template-columns: 1fr auto;
  margin-left: 10px;
  .label {
    grid-column: 1;
    padding-right: 10px;
  }
  .price {
    grid-column: 2;
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
}
</style>
