<template>
  <div class="card">
    <div class="profile" data-aos="fade-up">
      <div class="titles">
        <h3>{{ name }}</h3>
        <h4 class="title">{{ title }}</h4>
      </div>
      <div class="image">
        <div class="image-container">
          <img :src="image" />
        </div>
      </div>
      <p style="white-space: pre-line" class="text">{{ text }}</p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Profile',
  props: ['image', 'name', 'title', 'text', 'isOneLine'],
};
</script>
<style lang="scss" scoped>
@import '../styles/colors.scss';
@import '../styles/responsive.scss';
@mixin width($value) {
  width: $value;
  height: $value;
  min-width: $value;
  min-height: $value;
  max-width: $value;
  max-height: $value;
}

.profile {
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  padding: 10px;
  .titles {
    grid-row: 1;
    grid-column: 1;
    display: inline;
    margin: 0 20px 0 auto;
  }
  .image {
    grid-row: 1;
    grid-column: 2;
  }
  .title {
    color: $silver;
  }
  .text {
    grid-row: 2;
    grid-column-start: 1;
    grid-column-end: 3;
    display: inline;
    margin: auto;
  }
}
.card {
  height: 100% !important;
}

.image-container {
  border-radius: 100%;
  overflow: hidden;
  margin-bottom: 5px;
  @media screen and (max-width: $responsive-s) {
    @include width(70%);
  }
  @media screen and (min-width: $responsive-s) {
    @include width(180px);
  }
  @media screen and (min-width: $responsive-m) {
    @include width(150px);
  }
  @media screen and (min-width: $responsive-l) {
    @include width(180px);
  }
  img {
    border-radius: 100%;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    display: block;
  }
}
h3 {
  margin: 0;
  font-family: 'Pacifico', serif;
  letter-spacing: 2px;
}
h4 {
  margin: 0;
  color: $primary;
}
</style>
