<template>
  <div id="app">
    <Header />
    <div class="anchor" id="about"></div>
    <div id="about-section">
      <About />
    </div>
    <div class="anchor" id="team"></div>
    <div id="team-section">
      <Team />
    </div>
    <div id="gallery-section">
      <Gallery />
    </div>
    <div class="anchor" id="prices"></div>
    <div id="prices-section">
      <Prices />
    </div>
    <div class="anchor" id="contact"></div>
    <div id="contact-section">
      <Contact />
    </div>
  </div>
</template>

<script>
import Header from './components/Header.vue';
import Prices from './components/Prices.vue';
import Contact from './components/Contact.vue';
import Team from './components/Team.vue';
import About from './components/About.vue';
import Gallery from './components/Gallery.vue';

export default {
  name: 'App',
  components: {
    Header,
    Prices,
    Contact,
    Team,
    About,
    Gallery,
  },
  mounted() {
    if (window.location.href.includes('#')) {
      // eslint-disable-next-line no-restricted-globals
      history.replaceState(null, null, ' ');
    }
  },
};
</script>

<style lang="scss">
@import 'main.scss';
.anchor {
  position: relative;
  top: -50px;
}
.card {
  background-color: $secondary;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
</style>
