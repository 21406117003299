<template>
  <div class="preload" :class="'image' + more(1)"></div>
  <div class="preload" :class="'image' + more(2)"></div>
  <div class="preload" :class="'image' + more(3)"></div>
  <div class="preload" :class="'image' + more(4)"></div>
  <div class="carrousel" data-aos="fade-up">
    <div class="grid-1 border" :class="'image' + photoIndex"></div>
    <div class="grid-2 border" :class="'image' + more(1)"></div>
    <div class="grid-3 border" :class="'image' + more(2)"></div>
    <div class="grid-4 border" :class="'image' + more(3)"></div>
    <div class="grid-5 border" :class="'image' + more(4)"></div>
    <div class="grid-6 border" :class="'image' + more(5)"></div>
  </div>
</template>
<script>
export default {
  name: 'Carrousel',
  data() {
    return {
      photoIndex: 1,
    };
  },
  methods: {
    more(i) {
      const result = this.photoIndex + i;
      if (result > 12) {
        return result - 12;
      }
      return result;
    },
  },
  mounted() {
    setInterval(() => {
      if (this.photoIndex === 12) {
        this.photoIndex = 0;
      }
      this.photoIndex += 1;
    }, 5000);
  },
};
</script>
<style lang="scss" scoped>
@import '../styles/colors.scss';
@import '../styles/responsive.scss';
.preload {
  width: 0;
  height: 0;
}
.carrousel {
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 50px;
  padding: 5px 0;
  height: 140vh;
  max-height: 1500px;
  @media screen and (max-width: $responsive-l) {
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: $responsive-m) {
    grid-template-rows: auto;
    grid-template-columns: 1fr;
    height: 90vh;
  }
}
.grid-1 {
  grid-column: 1;
}
.grid-2 {
  grid-column: 2;
  @media screen and (max-width: $responsive-m) {
    display: none;
  }
}
.grid-3 {
  grid-row: 1;
  grid-column: 3;
  @media screen and (max-width: $responsive-l) {
    grid-row: 2;
    grid-column: 1;
  }
  @media screen and (max-width: $responsive-m) {
    display: none;
  }
}
.grid-4 {
  grid-row: 2;
  grid-column: 1;
  @media screen and (max-width: $responsive-l) {
    grid-row: 2;
    grid-column: 2;
  }
  @media screen and (max-width: $responsive-m) {
    display: none;
  }
}
.grid-5 {
  grid-row: 2;
  grid-column: 2;
  @media screen and (max-width: $responsive-l) {
    display: none;
  }
}
.grid-6 {
  grid-row: 2;
  grid-column: 3;
  @media screen and (max-width: $responsive-l) {
    display: none;
  }
}
.border {
  border: 2px solid $ternary;
  border-radius: 5px;
}
@for $i from 1 to 13 {
  .image#{$i} {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: url('../assets/rea#{$i}.jpg');
    height: 100%;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
}
</style>
