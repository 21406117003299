<template>
  <div v-for="image in images" :key="image" class="preload" :class="image"></div>
  <div id="image-slider" class="image1">
    <div class="overlay">
      <div class="container">
        <div class="texts" data-aos="flip-left">
          <h1 id="aboutTitle">
            <div>Natiff Coiffure</div>
            <div>Salon mixte</div>
            <div class="small">(Homme - Femme - Enfant)</div>
          </h1>
          <p>
            Entrer chez Natiff, au cœur d'Engis, ce n’est pas entrer dans un simple salon de
            coiffure. C'est entrer dans un endroit entièrement dédié aux cheveux et à leur beauté.
            Natacha Pynnaert vous accueille chez elle, dans un cadre moderne et élégant. Elle prend
            le temps pour comprendre vos besoins et vous apporter un conseil parfaitement adapté,
            classique, ou décalé.
          </p>
          <h4>ALORS OSEZ LE CHANGEMENT !</h4>
          <p>Natiff vous attends pour un soin, un moment relaxant et beaucoup de savoir-faire.</p>
          <div class="footer">
            <div class="footer-first text-large">
              Uniquement sur RDV du lundi au samedi de 9h à 18h.
              <div>Fermé le mardi.</div>
            </div>

            <div>
              <div class="">Suivez nous sur Facebook et Instagram.</div>
              <a class="video-link mt-1" v-on:click="startVideo" href="#video-anchor">
                &#10149; Video de présentation
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="anchor" id="video-anchor"></div>
  <div class="video-container">
    <div class="video">
      <video controls id="video">
        <source :src="require('@/assets/presentation.mp4')" />
      </video>
    </div>
  </div>
</template>
<script>
export default {
  name: 'About',
  data() {
    return {
      images: ['image1', 'image3', 'image4', 'image2'],
    };
  },
  mounted() {
    const slider = document.getElementById('image-slider');
    let i = 0;
    setInterval(() => {
      if (i < this.images.length - 1) {
        i += 1;
      } else {
        i = 0;
      }
      this.images.forEach((image, index) => {
        slider.classList.remove(image);
        if (i === index) {
          slider.classList.add(image);
        }
      });
    }, 3000);
  },
  methods: {
    startVideo() {
      document.getElementById('video').play();
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../styles/colors.scss';
@import '../styles/responsive.scss';
.mt-1 {
  margin-top: 10px;
}
.text-large {
  font-weight: bold;
  font-size: 110%;
}
.anchor {
  position: relative;
  top: -50px;
}
.video-link {
  display: inline-block;
  text-decoration: none;
  font-style: italic;
  color: $ternary;
}
.video-container {
  background-color: $primary;
  padding: 10vh 0;
  display: flex;
}
.video {
  width: 80%;
  margin: auto;
  text-align: center;
  video {
    max-height: 70vh;
    max-width: 100%;
  }
}

.image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100vh;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.image1 {
  @extend .image;
  background-image: url('../assets/cover1.jpg');
}
.image2 {
  @extend .image;
  background-image: url('../assets/cover2.jpg');
}
.image3 {
  @extend .image;
  background-image: url('../assets/cover3.jpg');
}
.image4 {
  @extend .image;
  background-image: url('../assets/cover4.png');
}
.preload {
  width: 0;
  height: 0;
}
.overlay {
  background-color: $primary-light;
  height: 100%;
  color: $ternary;
}
.container {
  height: 100%;
  display: flex;
  position: relative;
  .texts {
    margin: auto;
  }
}
.small {
  font-size: 60%;
  vertical-align: center;
}
.icon {
  display: inline-block;
  text-align: center;
  font-size: 30px;
  position: relative;
  top: 2px;
  cursor: pointer;
  color: $ternary;
  text-decoration: none;
}
.footer {
  display: flex;
  justify-content: space-between;
  .footer-first {
    margin-right: 20px;
  }
}
h4 {
  margin: 0.8em 0 !important;
}
</style>
