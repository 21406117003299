<template>
  <section>
    <div class="container">
      <h2 data-aos="fade-up">
        Contact
        <a href="tel:0471724591"><span class="gg-phone icon hover-secondary ternary"></span></a>
        <a href="mailto:contact@natiff.be"
          ><span class="gg-mail icon hover-secondary ternary"></span
        ></a>
      </h2>
      <div class="grid">
        <div class="header" data-aos="fade-up">
          <div>
            <span class="heading">Un renseignement ?</span> N'hésitez pas à nous contacter par
            téléphone ou par mail.
          </div>
        </div>
        <div class="contact" data-aos="fade-up">
          <h3>Un Rendez-Vous ?</h3>
          <ul>
            <li>
              <a href="tel:0471724591" class="bigger"
                ><span class="gg-phone icon"></span>0471 72 45 91</a
              >
            </li>
          </ul>
          <h3>La prise de rdv se fait uniquement par téléphone</h3>
        </div>
        <div class="address" data-aos="fade-up">
          <h3>Adresse</h3>
          <h4>
            <a
              href="https://goo.gl/maps/zXC79i4qjAQaxK7z7"
              class="no-decoration white hover-primary"
              target="_blank"
            >
              Rue Joseph wauters, 3 - 4480 Engis
              <span class="gg-pin icon"></span
            ></a>
          </h4>
          <h3>Heures d'ouverture</h3>
          <h4>Uniquement sur RDV</h4>
          <h4>
            Du lundi au samedi, de 9h à 18h. <br />
            Fermé le mardi.
          </h4>
        </div>
        <div class="footer" data-aos="fade-up">
          Natiff Coiffure (PYNNAERT Natacha BE0641.877.902) - Rue Joseph Wauters,3 à 4480 Engis -
          <a href="mailto:contact@natiff.be" class="white no-decoration hover-primary"
            >contact@natiff.be</a
          >
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: 'Contact',
};
</script>
<style lang="scss" scoped>
@import '../styles/colors.scss';
@import '../styles/responsive.scss';
section {
  background-color: $primary;
  color: $ternary;
  padding-bottom: 30px;
  min-height: substr(100vh, 50px);
}
.card {
  background-color: $secondary;
  margin: 35px 10px;
  padding: 10px;
  color: $ternary;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  text-align: center;
  @media screen and (max-width: $responsive-m) {
    margin: 15px 0;
  }
}
h2 {
  border-bottom: 2px solid $primary;
}
.heading {
  color: $silver;
  display: block;
}
.grid {
  display: grid;
  grid-template-rows: repeat(4, auto);
  grid-template-columns: repeat(4, 1fr);
  @media screen and (max-width: $responsive-m) {
    grid-template-rows: repeat(4, auto);
    grid-template-columns: repeat(1, 1fr);
  }
  .header {
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row: 1;
    font-size: 35px;
  }
  .contact {
    @extend .card;
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .rdv-info {
      padding: 20px 0;
    }
    ul {
      padding: 0;
    }
    li {
      list-style: none;
      margin: 15px;
      a {
        display: block;
        background-color: $primary;
        text-decoration: none;
        color: $ternary;
        padding: 10px 0;
        width: 100%;
        border-radius: 10px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        .gg-phone {
          position: relative;
          top: 5px;
        }
      }
    }
  }
  .address {
    @extend .card;
    grid-column-start: 3;
    grid-column-end: 5;
    grid-row: 2;
    @media screen and (max-width: $responsive-m) {
      grid-column: 1;
      grid-row: 3;
    }
  }
  .footer {
    @extend .card;
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row: 3;
    @media screen and (max-width: $responsive-m) {
      grid-column: 1;
      grid-row: 4;
    }
  }
}
.icon {
  display: inline-block;
  margin: 0 10px;
}
.secondary {
  color: $secondary;
}
.gg-mail {
  position: relative;
  bottom: 2px;
}
.white {
  color: $ternary;
}
.no-decoration {
  text-decoration: none;
}
.hover-primary:hover {
  color: $primary !important;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.hover-secondary:hover {
  color: $secondary !important;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.bigger {
  font-size: 120%;
}
.ternary {
  color: $ternary;
}
</style>
